$(function(){
	//TOPICS切替タブ
	var t = $('.topics-categories__list'),
		c = 'current',
		b = $('.topics-posts-wrap__box');
	t.on('click',function(){
		if(!$(this).hasClass(c)){
			var i = t.index(this);
			t.add(b).removeClass(c);
			t.eq(i).addClass(c);
			b.eq(i).addClass(c);
		}
	});
});