$(function(){
	//スクロールでヘッダーにクラス付与
	// var c = 'header__head--scroll';
	// $(window).scroll(function(){
	// 	scrollHeaderClass();
	// });
	// scrollHeaderClass();
	
	// function scrollHeaderClass(){
	// 	var s = $(window).scrollTop();
	// 	if(s > 0){
	// 		$('.header__head').addClass(c);
	// 	}else{
	// 		$('.header__head').removeClass(c);
	// 	}
	// }

    var $body = $('body');

	//フッターバナー
	$(".footer-banners").slick({
		slidesToShow: 4,
		centerMode: true,
		variableWidth: true,
		autoplay: true,
		responsive: [{
			breakpoint: 768,
			settings: {
				centerMode: true,
				slidesToShow: 1
			}
		}]
	});
	
	//スマホメニュー展開
	$(".sp-menu-toggle").on('click',function(){
		$(this).add('.global-menu-sp').toggleClass('active');
		$('.global-menu-sp').scrollTop(0);
	});
	$(".global-menu-sp__link--toggle").on('click',function(){
		$(this).toggleClass('active').next().slideToggle();
	});
	
	//スマホ時のみ電話リンク差し込み
	var ua = navigator.userAgent;
	if (ua.indexOf('iPhone') > 0 && ua.indexOf('iPod') == -1 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0 && ua.indexOf('SC-01C') == -1 && ua.indexOf('A1_07') == -1) {
		$('.tel-link').each(function () {
			var str = $(this).data("number");
			$(this).wrap('<a href="tel:' + str.replace(/-/g, '') + '" class="number-link"></a>');
		});
	}

    //ページ内スクロール
    $.smooziee();

	//アンカーリンクスクロール
	// $(document).on('click', 'a[href^="#"]', function () {
	// 	// headerの高さを入力　↓
	// 	var headerHight = 88;
	// 	// スクロールの速度
	// 	var speed = 400; // ミリ秒
	// 	// アンカーの値取得
	// 	var href = $(this).attr("href");
	// 	// 移動先を取得
	// 	var target = $(href === "#" || href === "" ? 'html' : href);
	// 	// 移動先を数値で取得
	// 	var position = target.offset().top-headerHight;
	// 	// スムーススクロール
	// 	$('body,html').animate({
	// 		scrollTop: position
	// 	}, speed, 'swing');
	// 	return false;
	// });

	//ライトボックス
	$(".lightbox").on('click',function(event){
		event.preventDefault();
		$(this).next('.lightbox_content').addClass("on");
		$(".lightbox_layer").addClass("on");
	});
	$(".lightbox_layer").on('click',function(event){
		event.preventDefault();
		$('.lightbox_content').removeClass("on");
		$(".lightbox_layer").removeClass("on");
	});

	//サブナビアコーディオン
	var ua = navigator.userAgent;
	if (ua.indexOf('iPhone') > 0 && ua.indexOf('iPod') == -1 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0 && ua.indexOf('SC-01C') == -1 && ua.indexOf('A1_07') == -1){
		var min_height = 145;
    	var ani_duration = 145;
	}
	else{
		var min_height = 140;
    	var ani_duration = 140;
	}
    $('.live_subnavi_btn').click(function(){
        var athis = $(this);
        var show_text = $('.live_subnavi');
        var original_height = show_text.css({height : 'auto'}).height();

        if(show_text.hasClass('open')){
          //--CLOSE--
          var scroll_offset = $("html,body").scrollTop() - original_height + min_height;

          $("html,body").animate({ scrollTop: scroll_offset }, ani_duration);
          show_text.animate({height:min_height}, ani_duration, function(){
          });
          show_text.removeClass('active').removeClass('open');
          $('.live_subnavi_btn_area').removeClass('open');
        }else{
            //--OPEN--
            show_text.height(min_height).animate({height:original_height},ani_duration, function(){
            show_text.height('auto').addClass('open');
          });
          show_text.addClass('active');
          $('.live_subnavi_btn_area').addClass('open');
        }
    });

    //LIVE詳細ページサイドバーアコーディオン
    $(".sidebar-box-topics").each(function () {
		var num = $(this).find('ul.side-post li').length;
		if(num >= 12){
			$(this).find('.side-post_btn').css('display','block');
		}
	});
    $(".side-post_btn").click(function(){
        $(".side-post__list:nth-child(n+10)").toggleClass('active');
        $(this).toggleClass("close");
        var position = $(".side-post").offset().top;
        console.log(position);
        if(!($(".side-post_btn").hasClass("close"))){
            $("html,body").animate({
            scrollTop : position - 150 //スクロール位置調整
            });
        }
    });

    //アパートページアコーディオン
    $(".plannning_navi_toggle_c").hide();
    $(".plannning_navi_toggle_b").on('click',function(){
        // event.preventDefault();
        $(this).toggleClass("on");
        $(this).next(".plannning_navi_toggle_c").slideToggle();
        return false;
    });


    //スクロールしたらクラスを付与
    $(window).scroll(function () {
        if ($(this).scrollTop() > 300) {
            $body.addClass("scroll");
        } else {
            $body.removeClass("scroll");
        }
    });

});
